let config = {
    s3: {
        REGION: "us-west-2",
        BUCKET: "forwood-pluto-dev-forwoodid",
        BATCH_PROCESS_BUCKET_NAME: "forwood-pluto-dev-forwoodid-batch-user-process"
    },
    appSyncAPI: {
        REGION: "us-west-2",
        URL: "https://wxsbf4k77ndzddcmdh5my4pve4.appsync-api.us-west-2.amazonaws.com/graphql",
    },
    apiGateway: {
        REGION: "us-west-2",
        URL: "https://0q2c6fsp0c.execute-api.us-west-2.amazonaws.com/env",
        USER_TOKEN_URL: "https://irlnbg2kt8.execute-api.us-west-2.amazonaws.com/prod"
    },
    configurationService: {
        URL: "https://config.dev.pluto.forwoodsafety.com",
        WEBSOCKET: "wss://hhepu5o7r3.execute-api.us-west-2.amazonaws.com/dev"
    },
    cognito: {
        REGION: "us-west-2",
        USER_POOL_ID: "us-west-2_hUzNbPJud",
        APP_CLIENT_ID: "3obml76g2p1n3k28red9900l5",
        IDENTITY_POOL_ID: "us-west-2:845d0b29-9a8b-4d12-a3ea-50797c2a61ec",
        USERPOOL_HOSTED_DOMAIN: "forwood-pluto-id-dev",
        USERPOOL_ADFS_CALLBACK_URL: "https://id.dev.pluto.forwoodsafety.com",
        USERPOOL_ADFS_LOGOUT_URL: "https://id.dev.pluto.forwoodsafety.com",
        AUTHENTICATED_ROLE_ARN: "arn:aws:iam::133361747169:role/dev-CA",
        get USERPOOL_ADFS_LAUNCH_URL () {
          return 'https://'+this.USERPOOL_HOSTED_DOMAIN+'.auth.'+this.REGION+'.amazoncognito.com/authorize?redirect_uri='+this.USERPOOL_ADFS_CALLBACK_URL+'&response_type=code&client_id='+this.APP_CLIENT_ID;
        }
    },
    samlProvider: {
      NAME: 'ForwoodAzure',
      SUPPORTED_LIST: 'ForwoodAzure'
    },
    reactApp: {
        VERSION: "1.34.3",
        HOSTNAME: "id.dev.pluto.forwoodsafety.com",
        COOKIE_DOMAIN: ".dev.pluto.forwoodsafety.com",
        SECURE_PROTOCOL: true,
        FORWOOD_COMPANY_ID: "647a628a-eaa3-49ad-89e3-05c17fba683f",
        FORWOOD_HOMEPAGE_MICROFRONTEND_URL: "https://homepage.dev.pluto.forwoodsafety.com"
    }
};

export default config;
